export const colors = {
    "blue": "rgba(31, 30, 64, 1)",
    "blue01": "rgba(31, 30, 64, .1)",
    "blue02": "rgba(31, 30, 64, .2)",
    "blue03": "rgba(31, 30, 64, .3)",
    "blue04": "rgba(31, 30, 64, .4)",
    "blue05": "rgba(31, 30, 64, .5)",
    "blue06": "rgba(31, 30, 64, .6)",
    "blue07": "rgba(31, 30, 64, .7)",
    "blue08": "rgba(31, 30, 64, .8)",
    "blue09": "rgba(31, 30, 64, .9)",
    "green": "rgba(97, 168, 157, 1)",
    "green01": "rgba(97, 168, 157, .1)",
    "green02": "rgba(97, 168, 157, .2)",
    "green03": "rgba(97, 168, 157, .3)",
    "green04": "rgba(97, 168, 157, .4)",
    "green05": "rgba(97, 168, 157, .5)",
    "green06": "rgba(97, 168, 157, .6)",
    "green07": "rgba(97, 168, 157, .7)",
    "green08": "rgba(97, 168, 157, .8)",
    "green09": "rgba(97, 168, 157, .9)",
    "lightgreen": "rgba(120, 190, 126, 1)",
    "lightgreen01": "rgba(120, 190, 126, .1)",
    "lightgreen02": "rgba(120, 190, 126, .2)",
    "lightgreen03": "rgba(120, 190, 126, .3)",
    "lightgreen04": "rgba(120, 190, 126, .4)",
    "lightgreen05": "rgba(120, 190, 126, .5)",
    "lightgreen06": "rgba(120, 190, 126, .6)",
    "lightgreen07": "rgba(120, 190, 126, .7)",
    "lightgreen08": "rgba(120, 190, 126, .8)",
    "lightgreen09": "rgba(120, 190, 126, .9)",
}