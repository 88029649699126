import React from "react";

import Footer from './footer'
import Header from './header'

const Container = ({ children }) => {
  return (
    <>
    
      <Header 
        siteTitle="MED.hjelper"
      />

      <main id="main" tabIndex="-1" style={{'outline': 'none'}}>
        {children}
      </main>

      <Footer />

    </>

  );
};

export default Container;
