import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { css } from '@emotion/css';

import LinkComponent from "./../editorial/link";
import SoMe from "./SoMe";
import CookieDisclaimer from "./cookieDisclaimer";
import Body from "../editorial/body";

import "../../styling/libraries.scss"

import { colors } from './../../styling/colors'

const footerStyling = css`
  background: ${colors.blue};
  color: white;
  font-family: sans-serif;
  padding: 2em;
  line-height: 1.5em;

  * {
    color: white;
  }

  .title {
    color: white;
  }

  p a {
    color: #fff;
    text-decoration: underline;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }

`

const Footer = () => (

  <footer className={footerStyling}>

    <div className="container py-5">

      <StaticQuery
        query={graphql`
          query {
            allSanitySiteSettings {
              nodes {
                navigation {
                  title
                  id
                  slug {
                    current
                  }
                }
                _rawContactInfo
                _rawAddress
              }
            }
          }
        `}
        render={data => {
          console.log("data", data.allSanitySiteSettings.nodes[0].navigation)

          return (
            <div className="columns">
              <div className="column is-3">
      
                <h2 className="title is-5">Kontaktpersoner</h2>

                {data.allSanitySiteSettings.nodes[0]._rawContactInfo &&
                  <Body blocks={data.allSanitySiteSettings.nodes[0]._rawContactInfo} />
                }

              </div>

              <div className="column is-4">

                <h2 className="title is-5">Neuro-SysMed</h2>

                {data.allSanitySiteSettings.nodes[0]._rawContactInfo &&
                  <Body blocks={data.allSanitySiteSettings.nodes[0]._rawAddress} />
                }

                </div>

                <div className="column is-3">

                  <h2 className="title is-5">Snarvei</h2>

                  <ul>

                    {data.allSanitySiteSettings.nodes[0].navigation.map(item => 
                      <li key={item.id} className={`mb-2`}>
                        <LinkComponent 
                          chevron
                          white
                          slug={`/${item.slug.current}`}
                          linkText={item.title}
                        />
                      </li>
                    )}

                  </ul>
                </div>

                <div className="column is-2">
                <h2 className="title is-5">Følg oss</h2>

                <SoMe fill="white" />

                </div>


            </div>
          )

        }}
      />

    </div>

    <CookieDisclaimer />

  </footer>
)

export default Footer;
