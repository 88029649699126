import React from "react";
import { Link } from "gatsby";
import { css } from '@emotion/css';

import ArrowRight from "./../../icons/arrow-right.js";
import ChevronRight from "./../../icons/Chevron-right.js";
import { colors } from "./../../styling/colors";

const maybeImage = (mainImage, alt) => {
  let img = null;

  if (mainImage && mainImage.asset) {
    img = (
      <img 
        className="logo"
        src={mainImage.asset.fixed.src || ''} 
        alt={alt}
      />
    )
  }

  return img;

};

const LinkStyling = css`
  &.bold {
    font-weight: 600;
  }
  &.underlined {
    .linkText {
      text-decoration: underline;
    }
  }
  &.white {
    .linkText {
      color: white;
    }
  }
  a, a:hover {
    text-decoration: none;
  }
  &.underlined a:hover .linkText {
    text-decoration: none;
  }
  a:not(.button) {
    display: inline-block;
    color: ${colors.blue};
    border-radius: 3px;
    position: relative;

    .linkText {
      transition: .25s;
      display: inline-block;
    }

    .animate-icon-before {
      position: absolute;
      transition: .25s ease-in-out;
      left: 0;
      top: 3px;
      display: flex;
      align-items: center;
      + .linkText {
        margin-left: 1.5em;
      }
    }

    .animate-icon-after {
      position: absolute;
      right: -1em;
      transition: .25s ease-in-out;
    }

    &:hover {
      .animate-icon-after {
        right: -1.5em;
      }
      .animate-icon-before {
        left: .4em;
      }
    }
  }

  a:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.green};
  }

  .button {
    background: ${colors.blue};
    transition: .25s;
    display: inline-flex;
    padding: 0.5em 1em;
    border-radius: 3px;
    color: white;

    .linkText {
      color: white;
    }

    &.circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 100%;
        display: block;
        width: 40px;
        height: 40px;
        box-shadow: 0px 2px 2px rgba(31, 30, 64, 0.2);
      }
    }

    &:hover {
      background: ${colors.blue09};
      box-shadow: 0 0 0 5px ${colors.green};
    }

    &:focus {
      box-shadow: 0 0 0 5px ${colors.green};
    }
  }

`
  
function LinkComponent (props) {
   
    const img = maybeImage(props.mainImage, props.linkText);

    return (

        <span key={props._key}>

          { props.slug

            ? <span className={`${LinkStyling} ${props.arrow ? 'arrow' : ''} ${props.white ? 'white' : ''}`}>
                <Link 
                  to={props.slug}
                  className={props.className}
                >
                  <span className="is-flex is-align-items-center">

                    {props.arrow && <span className="mr-3"><ArrowRight fill={props.white ? "white" : colors.blue}/></span>}

                    <span className="linkText">
                      {props.linkText}
                    </span>

                    {props.chevron && <span className="ml-3 animate-icon-after">
                      <ChevronRight fill={props.white ? "white" : colors.blue} />
                    </span>}

                  </span>
                </Link>
              </span>

              // External link
              : <span className={`${LinkStyling} ${props.bold ? 'bold' : ''} ${props.white ? 'white' : ''} ${props.underlined ? 'underlined' : ''}`}>
                  <a
                    className={props.className ? props.className : ''}
                    href={props.externalURL} 
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Åpner ekstern lenke i nytt vindu"
                  >

                  <span className="is-flex">

                    {props.arrow && 
                      <span className="animate-icon-before">
                        <span className="arrow mr-5">
                          <span>
                            <ArrowRight fill={(props.className && props.className.includes('button')) || (props.white ? 'white' : colors.blue)} />
                          </span>
                        </span>
                      </span>
                    }

                    {img 
                      ? <span>
                          {img}
                          <span className="is-sr-only">{props.linkText}</span>
                        </span>
                      : <span className="linkText">
                          {props.linkText}
                        </span>
                    }

                  </span>

                </a>
               </span>
          }

        </span>

    )
}

export default LinkComponent;