import React, {useState} from 'react'
import {Link} from 'gatsby'
import { css } from '@emotion/css';

import Button from '../editorial/button.js'

import "../../styling/libraries.scss"
import { colors } from './../../styling/colors'

const cookieDisclaimer = css`
  background: ${colors.blue09};
  color: white;
  font-family: sans-serif;
  padding: 2em;
  line-height: 1.5em;
  position: fixed;
  max-width: 430px;
  bottom: 0;
  right: 0;
  font-size: 16px;
  opacity: 0;
  transition: .25s;
  &.open {
    opacity: 1;
  }
`

function CookieDisclaimer() {

  
  const readCookie = (name) => {
    if (name) {
      if (typeof document !== 'undefined') {
        return (
          document.cookie.match('(^|;)\\s*' + (name) + '\\s*=\\s*([^;]+)')?.pop() || ''
        )
      }
    }
  }
  
  console.log('cookieConsent', readCookie('cookieConsent'))

  const [closeConsent, setCloseConsent] = useState(readCookie('cookieConsent') === 'true');

  function handleClosing() {
    setCloseConsent(true)
    if (typeof document !== 'undefined') {
      document.cookie = "cookieConsent=true";
    }
  }

  if (readCookie('cookieConsent') !== 'true') {
    return (<>
      <div className={`${cookieDisclaimer} ${!closeConsent ? 'open' : ''}`} style={{'opacity': readCookie('cookieConsent') !== 'true' ? 1 : 0}}>
        <p>Dette nettstedet bruker cookies for å samle inn anonymisert brukerstatistikk slik at vi kan gi deg den beste opplevelsen og relevant innhold. <Link to="/cookies">Les mer</Link></p>
        <Button onClick={() => handleClosing()} buttonText="OK" />
        {/* <Button buttonText="Jeg godtar ikke" /> */}
      </div></>
    )
  }
  return null
}

export default CookieDisclaimer
;