import { React, useEffect, useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { css } from '@emotion/css';

import IconHamburger from './../../icons/IconHamburger'
import logo from "./../../images/logo.svg";

import { colors } from "./../../styling/colors";
import "../../styling/libraries.scss"
import campaign from "../../../sanity/schemas/campaign";

const mobileMenuTrigger = css`
  .button {
    background: transparent;
    border: 3px solid transparent;
    outline: none;

    * {
      display: block;
    }

    &:focus {
      border-radius: 3px;
      border: 3px solid ${colors.green};
    }
  }
`

const skipToContent = css`
  .button {
    top: -2em;
    position: absolute;
    opacity: 0;
    background: 'transparent';
    border-radius: 3px;
    border: 0;
    box-shadow: 0px 2px 2px rgba(31, 30, 64, 0.2);
    color: ${colors.blue};
    display: inline-block;
    font-size: 1em;
    padding: 0.5em 1em;
    transition: .25s;

    &:focus {
      top: 0;
      background: white;
      position: relative;
      margin: 0;
      opacity: 1;
      box-shadow: 0 0 0 3px ${colors.green};
      color: ${colors.blue};
      outline: none;
    }
  }
`

const link = css`
  .navbar-item {
    border-radius: 3px;
    transition: .25s;
    border: 3px solid transparent;
    outline: none;
    padding-left: 0;
    padding-right: 0;
    margin: 0 1em;

    &[aria-current]:not([aria-current="false"], .logo),
    &:hover {
      outline: none;
      color: ${colors.blue};
      background: transparent;
      border-bottom: 2px solid ${colors.blue};
    }

    &[aria-current]:not([aria-current="false"]) {
      font-weight: 700;
    }

    &:focus {
      box-shadow: 0 0 0 3px ${colors.green};
      color: ${colors.blue};
    }
  }
`

const logoStyling = css`
  img {
    max-height: 75px;
  }
  a {
    display: inline-block;
  }
  a:focus {
    box-shadow: 0 0 0 3px ${colors.green};
    border-radius: 3px;
    color: ${colors.blue};
    outline: none;
  }
`

function Header({ siteTitle }) {

  const [openMenu, setOpenMenu] = useState(false);
  const [hiddenElements, setHiddenElements] = useState([]);
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      if (window.location.pathname === "/parkinson/" || 
        window.location.pathname === "/parkinson" || 
        window.location.pathname === "/midi/" || 
        window.location.pathname === "/midi") {
        setHiddenElements(["kontakt-oss"])
      }
    }
  }, [isBrowser]);

  function ScrollToContent() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, document.getElementById('main').getBoundingClientRect().y)
    }

    const main = document.getElementById('main')
    main.focus()

  }

  return (

    <div className={`container`}>
        
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="mx-2" style={{'flexShrink': '0'}}>

          <div className={skipToContent}>
            <button 
              className="button"
              onClick={() => ScrollToContent()}
              id="skip-to-content">
              Gå til hovedinnhold
            </button>
          </div>

          <div className="navbar-brand is-flex is-align-items-center" style={{'maxWidth': '100%'}}>

            <div className={`${logoStyling} p-4`} style={{'flex': '1'}}>
              <Link to="/" className="logo">
                <img src={logo} alt={siteTitle} />
              </Link>
            </div>

            <div className={`is-mobile ${mobileMenuTrigger}`}>
              <button 
                onClick={() => setOpenMenu(!openMenu)} 
                className={`is-hidden-desktop p-3 button`}
                aria-label="Meny" 
                aria-expanded={openMenu}
                data-target="navbarBasicExample">
              
                <IconHamburger isOpen={openMenu} />

                <span className="is-sr-only">

                  {openMenu ? "Lukk meny" : "Åpne meny"}

                </span>

              </button>
            </div>
            
          </div>
        </div>

        <div id="navbarBasicExample" className={`navbar-menu ${openMenu ? 'is-active' : ''}`} style={{'flex': '1'}}>
          <div className="navbar-end is-align-items-center">

            <div className="section p-2 mx-1">

              <div className="columns">

                <StaticQuery
                  query={graphql`
                    query {
                      allSanityCampaign {
                        nodes {
                          slug {
                            current
                          }
                        }
                      }
                      allSanitySiteSettings {
                        nodes {
                          navigation {
                            title
                            id
                            slug {
                              current
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    data.allSanitySiteSettings.nodes && 
                      data.allSanitySiteSettings.nodes[0].navigation.map(item => {

                        const campaignPages = data.allSanityCampaign.nodes
                          .map(item => "/" + item.slug.current + "," + "/" + item.slug.current + "/" + ",")
                          .join("")
                          .split(",")

                        return (

                          <div 
                          key={item.id} 
                          className={`ml-2 ${link}`} 
                          style={{"visibility": hiddenElements.includes(item.slug.current) ? "hidden" : ""}}
                          >

                          {isBrowser && item.slug.current === "kontakt-oss" && campaignPages.includes(window.location.pathname)
                            ? <span style={{"visibility": "hidden"}}>
                                <Link 
                                  key={"menu-item_"+item.title}
                                  className={`navbar-item`}
                                  to={`/${item.slug.current}`}
                                  activeClassName="active"
                                >
                                  {item.title}
                              </Link>
                              </span>
                            : <Link 
                                key={"menu-item_"+item.title}
                                className={`navbar-item`}
                                to={`/${item.slug.current}`}
                                activeClassName="active"
                              >
                                {item.title}
                            </Link>
                          }
                          
                        </div>

                        )
                      }
                        
                      )
                  )}
                />
              
              </div>
            </div>

          </div>

        </div>

      </nav>

    </div>
  )

};

export default Header;
