import React from 'react'
import PortableText from "react-portable-text"

const RichText = ({ portableTextContent }) => (
  <div>
    <PortableText
      // Pass in block content straight from Sanity.io
      content={portableTextContent}

      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{
        link: (props) => <a 
          href={props.href} 
          target="_blank" 
          aria-label="Åpner lenke i nytt vindu"
          rel="noreferrer">
          {props.children}
        </a>,
        li: ({ children }) => <li className="special-list-item">{children}</li>,
        someCustomType: RichText,
      }}
    />
  </div>
)

export default RichText;

// https://www.sanity.io/plugins/react-portable-text