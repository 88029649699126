import React from 'react';
import { graphql, StaticQuery } from "gatsby";
import { css } from '@emotion/css';

import Facebook from "./../../icons/Facebook";

const Link = css`
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const SoMe = ({fill}) => (

  <StaticQuery
    query={graphql`
      query {
        sanitySiteSettings {
          facebook
          twitter
      }
    }
    `}
    render={data => (
      <div className="is-flex">

        {data.sanitySiteSettings.facebook &&
          <a className={Link} target="_blank" rel="noreferrer" aria-label="Åpner lenke i nytt vindu" href={data.sanitySiteSettings.facebook}>
            <span className="is-flex mb-3">
              <span className="icon mr-3">
                <Facebook fill="white" />
              </span>
              <span style={{'flex': '1'}}>
                Facebook
              </span>
            </span>
          </a>
        }

        {/* {data.sanitySiteSettings.twitter &&
          <Link 
            className="button mr-4 p-2 circle" 
            linkText={
              <>
                <span className="icon">
                  <Twitter fill={fill} />
                </span>
                <span className="is-sr-only">MED.hjelper på Twitter</span>
              </>
            }
            externalURL={data.sanitySiteSettings.twitter}
          />
        } */}

      </div>
    )}
  />
            
);
export default SoMe;
