import React from "react"
import { css, keyframes } from '@emotion/css';

import { colors } from "./../styling/colors";

const rotate90 = keyframes`
  100% {
    transform: rotate(90deg);
  }
`

const rotateMore = keyframes`
  100% {
    transform: rotate(135deg);
  }
`

const wrap = css`
  margin: -.75em;
  padding: 1em;
`

const closed = css`
  height: 16px;
  width: 16px;
  position: relative;
  display: block;
  * {
    transition: .45s ease-in-out;
    display: block;
  }
  svg {
    left: 0;
    position: absolute;
  }
  .line {
    background: ${colors.blue};
    border-radius: 2px;
    height: 2px;
    opacity: 1;
    width: 100%;
  }
  .lineWrap {
    height: 16px;
    width: 16px;
    position: absolute;
    opacity: 1;
    
    + .lineWrap:nth-of-type(2) {
      .line {
        margin-top: 7px;
      }
    }
    + .lineWrap:nth-of-type(3) {
      .line {
        margin-top: 14px;
      }
    }
  }
`

const open = css`
  animation: ${rotateMore} .5s ease forwards;
  animation-delay: .3s;
  position: relative;
  transform-origin: center;
  height: 16px;
  width: 16px;
  * { display: block; }
  .line {
    background: ${colors.blue};
    border-radius: 2px;
    height: 2px;
    opacity: 1;
    width: 100%;
  }
  .lineWrap {
    height: 16px;
    width: 16px;
    opacity: 1;
    position: absolute;
    + .lineWrap:nth-of-type(2) {
      .line {
        margin-top: 7px;
        opacity: 0;
      }
    }
    + .lineWrap:nth-of-type(3) {
      .line {
        margin-top: 14px;
      }
    }
  }
  
  .line1 {
    transition: .45s ease-in-out;
    transform: translate(0px, 7px);
    transform-origin: center;
  }
  .line3 {
    transition: .45s ease-in-out;
    transform: translate(0px, -7px);
    transform-origin: center;
  }
  .lineWrap1 {
    animation: ${rotate90} .5s ease forwards;
    animation-delay: .3s;
    transform-origin: center;
  }
`

const IconHamburger = ({isOpen}) => (
  <span className={wrap}>
    <span className={isOpen ? open : closed}>
      <span className="lineWrap lineWrap1">
        <span className="line line1" />
      </span>
      <span className="lineWrap">
        <span className="line" />
      </span>
      <span className="lineWrap lineWrap3">
        <span className="line line3" />
      </span>
    </span>
  </span>
)

export default IconHamburger