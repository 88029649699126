import React from "react";
import styled from '@emotion/styled';
import { colors } from "./../../styling/colors";

const Styling = styled.span`
  + span button {
    margin-left: .5em;
  }

  .button {
    background: ${props => props.transparent ? 'transparent' : colors.blue};
    border-radius: 3px;
    border: 0;
    box-shadow: ${props => !props.transparent ? '0px 2px 2px rgba(31, 30, 64, 0.2)' : ''};
    color: ${props => props.transparent ? colors.blue : 'white'};
    display: inline-block;
    font-size: 1em;
    padding: 0.5em 1em;
    transition: .25s;


    .button-text {
      text-decoration: ${props => props.underlined ? 'underline' : 'none'};
      color: ${props => props.transparent ? colors.blue : 'white'};
    }

    &.circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }

    &:hover {
      background: ${props => props.transparent ? 'transparent' : colors.blue09};
      box-shadow: 0 0 0 5px ${colors.green};
      .button-text {
        text-decoration: ${props => props.underlined ? 'none' : 'none'};
      }
    }

    &:focus {
      box-shadow: 0 0 0 5px ${colors.green};
    }
  }

`
  
function Button (props) {
   
    return (

      <Styling {...props}>

        <button className="button">
          <span className="button-text">
            {props.buttonText}
          </span>
        </button>
        
      </Styling>

    )
}

export default Button;